import { UploadImportedDriversMutation } from '@wise/graphql'
import { isDefined } from '@wise/utils'
import { groupBy, uniqBy } from 'lodash'

export class ImportDriverError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'ImportDriverError'
  }
}

type ResultType = UploadImportedDriversMutation['uploadImportedDrivers']
type ErrorType = Extract<
  ResultType,
  { __typename: 'ImportingDriversValidationErrors' }
>

const getRowFromMessage = (msg: string): string | null => {
  const res = /\[(\d+)\]/.exec(msg)?.[1]
  return res ? `Driver ${Number(res) + 1}` : `At least one driver`
}

const formatErrors = ({ errors }: ErrorType): string => {
  const grouped = groupBy(
    errors.map((e) => {
      const { __typename, message } = e
      const prefix = getRowFromMessage(message)
      switch (__typename) {
        case 'NameValidationError':
          return { prefix, message: 'an invalid name' }
        case 'PhoneValidationError':
          return { prefix, message: 'an invalid phone number' }
        case 'EmailValidationError':
          return { prefix, message: 'an invalid email' }
        default:
          return { prefix, message: 'an unknown issue' }
      }
    }),
    'prefix',
  )

  return (
    Object.entries(grouped)
      .map(([prefix, items]) => {
        const messages = uniqBy(items.filter(isDefined), 'message').map(
          (i, ix) => `${ix === 0 ? `has ` : ''}${i.message}`,
        )
        const lastMessage = messages.pop()
        const fullMessage =
          messages.length > 0
            ? messages.join(', ') + `, and ${lastMessage}`
            : lastMessage
        return `- ${prefix} ${fullMessage}.`
      })
      .join('\n') || 'There was an unexpected error.'
  )
}

export function importDriverErrorInvariant<T extends ResultType>(
  item: T | undefined | null,
): asserts item is Exclude<T, ErrorType> {
  if (!item) throw new Error('Invalid GraphQL response - no data was returned.')
  if (item.__typename !== 'ImportingDriversValidationErrors') return

  const formattedMessage = formatErrors(item)
  throw new ImportDriverError(formattedMessage)
}
