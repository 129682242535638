import React from 'react'

import { PortalMode } from '~shared/hooks/useMode'

export interface translateDriversTileDescriptionTextArgs {
  mainContractorId: string | null
  filtersLocation: string | null
  locationOptionlabel: React.ReactChild | undefined
  mode: PortalMode
  t: (translation: string, value?: unknown) => string
  userMCName?: string | null | undefined
  mainContractorOptionLabel: React.ReactChild | undefined
}

const translateDriversTileDescriptionText = ({
  mainContractorId,
  filtersLocation,
  locationOptionlabel,
  mode,
  t,
  userMCName,
  mainContractorOptionLabel,
}: translateDriversTileDescriptionTextArgs) => {
  return (driverStatus: 'active' | 'inactive' | 'total' | 'migrated') => {
    if (!mainContractorId && filtersLocation) {
      return t(
        `default_dashboard.${driverStatus}_drivers_stats_description_location`,
        {
          location: locationOptionlabel,
        },
      )
    }

    if (mainContractorId && mode === 'MCP') {
      return filtersLocation
        ? t(
            `default_dashboard.${driverStatus}_drivers_stats_description_mcID_location`,
            {
              who: userMCName || t(`default_dashboard.you`),
              location: locationOptionlabel,
            },
          )
        : t(
            `default_dashboard.${driverStatus}_drivers_stats_description_mcID`,
            {
              who: userMCName || t(`default_dashboard.you`),
            },
          )
    }

    if (mainContractorId && mode === 'WAP') {
      return filtersLocation
        ? t(
            `default_dashboard.${driverStatus}_drivers_stats_description_mcID_location`,
            {
              who:
                mainContractorOptionLabel ||
                t(`default_dashboard.this_main_contractor`),
              location: locationOptionlabel,
            },
          )
        : t(
            `default_dashboard.${driverStatus}_drivers_stats_description_mcID`,
            {
              who:
                mainContractorOptionLabel ||
                t(`default_dashboard.this_main_contractor`),
            },
          )
    }

    return t(`default_dashboard.${driverStatus}_drivers_stats_description`)
  }
}

export default translateDriversTileDescriptionText
