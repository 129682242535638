import { motion } from 'framer-motion'
import React from 'react'

import { ADD_DRIVERS_CSV_MC_ONBOARDING_DASHBOARD } from '~shared/consts/documents'
import Dashboard from '~shared/layouts/Dashboard/Dashboard'

import LimitedSetupOption from '@/dashboard/components/Dashboard/LimitedSetupOption'
import ImportDriversButton from '@/maincontractors/components/CompanySettings/ImportDriversButton'
import { useMaincontractor } from '@/maincontractors/hooks/useMaincontractor'
import { ONBOARDING_SUPPORT_EMAIL } from '@/mc-onboarding/consts/email'

import { AppPage } from '~types'

type SetupOptionsType = {
  testId: string
  icon: string
  title: string
  description: React.ReactChild | string
  buttonName?: React.ReactChild | string
  href?: string
  handleSubmit?: () => Promise<unknown> | void
  buttonDisabled?: boolean
  customButton?: React.ReactChild
}

const getSetupOptions = ({
  mcId,
}: {
  mcId: string | null | undefined
}): SetupOptionsType[] => [
  {
    testId: 'add-policies-option',
    icon: '/images/clipboard.svg',
    title: 'Add policies.',
    description:
      'You can add any policies you want adding to your driver onboarding process.',
    buttonName: 'Go to policies.',
    href: '/settings/document-library',
  },
  {
    testId: 'add-drivers-option',
    icon: '/images/van.svg',
    title: 'Add drivers.',
    description: (
      <div className='flex flex-col'>
        <p>
          We need some brief data about your drivers to set them up on the
          system.
        </p>
        <a
          href={ADD_DRIVERS_CSV_MC_ONBOARDING_DASHBOARD}
          rel='noopener noreferrer'
          target='_blank'
          className='mt-2 cursor-pointer font-extrabold text-purple underline hover:text-purple-900'
        >
          Download CSV
        </a>
      </div>
    ),
    customButton: (
      <ImportDriversButton testId='upload-drivers-button' mcId={mcId} />
    ),
  },
  {
    testId: 'add-users-option',
    icon: '/images/user-icon.svg',
    title: 'Add users.',
    description:
      'You can add other users within your business to also give them access to your Wise platform.',
    buttonName: 'Go to users.',
    href: '/settings/users',
  },
]

const MCPOnboardingDashboard: AppPage = () => {
  const mc = useMaincontractor()
  const mcId = mc?.id

  const SetupOptions = React.useMemo(() => getSetupOptions({ mcId }), [mcId])

  return (
    <Dashboard>
      <div className='flex min-h-full justify-center pt-16 pb-3 sm:pt-9 sm:pb-9'>
        <div className='min-w-250 sm:min-w-550 mx-4 flex w-full max-w-700 flex-col content-center items-stretch justify-center sm:max-w-[100vw]'>
          <h1 className='mx-4 mb-3 text-center text-3xl font-bold sm:mx-6 sm:mb-3 sm:text-4xl'>
            Your system is limited pending setup.
          </h1>
          <h2 className='mb-9 text-center text-xl tracking-normal sm:mb-12 sm:tracking-wider'>
            By adding the below information, our team can process your setup
            faster and unlock your system.
          </h2>
          <div className='min-w-250 flex w-full max-w-700 flex-col items-center justify-center sm:min-w-400 sm:max-w-[100vw]'>
            <div className='mb-6 sm:mb-0'>
              <div className='flex flex-col flex-wrap content-center justify-center gap-x-6 sm:flex-row'>
                {SetupOptions.map((step, ix) => {
                  return (
                    <LimitedSetupOption
                      key={ix}
                      testId={step.title}
                      icon={step.icon}
                      title={step.title}
                      description={step.description}
                      buttonName={step.buttonName}
                      buttonDisabled={step.buttonDisabled}
                      href={step.href}
                      handleSubmit={step.handleSubmit}
                      customButton={step.customButton}
                    />
                  )
                })}
              </div>
            </div>
          </div>
          <div className='justfiy-center flex flex-col items-center gap-y-2'>
            <h2 className='mt-6 text-center text-lg font-extrabold text-purple'>
              Having trouble?
            </h2>
            <p className='text-center'>
              Our team is always on hand to offer help - if you&apos;ve got any
              questions relating to your Wise system, please get in touch.
            </p>
            <a href={`mailto:${ONBOARDING_SUPPORT_EMAIL}`}>
              <motion.button
                whileTap={{ scale: 0.96 }}
                className='mt-4 rounded-full border-[3px] border-solid border-purple-400 px-3 py-2 text-xs font-extrabold text-purple shadow-darken-sm hover:shadow-md'
              >
                Contact us.
              </motion.button>
            </a>
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default MCPOnboardingDashboard
