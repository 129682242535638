import {
  useCanImportedDriversForMcQuery,
  useUploadImportedDriversMutation,
} from '@wise/graphql'
import { invariant, isNonEmptyString, showToastNotification } from '@wise/utils'
import * as React from 'react'

import Button from '~shared/components/Buttons/Button/Button'
import UploadCloudSVG from '~shared/components/Icons/svg/cloud-upload.svg'
import ConfirmModal from '~shared/components/Modal/preset/ConfirmModal/ConfirmModal'
import { triggerErrorModal } from '~shared/components/Modal/preset/GenericModal/GenericModal'
import { pushModal } from '~shared/components/Modal/useModal'
import { MAX_FILE_SIZE } from '~shared/consts/file'
import { fileToBase64String } from '~shared/utils/base64'
import { createComponent } from '~shared/utils/createComponent'

import {
  ImportDriverError,
  importDriverErrorInvariant,
} from '@/mc-onboarding/utils/importError'

interface Props {
  testId: string
  mcId?: string | null
}
const ImportDriversButton = ({
  testId,
  mcId: mainContractorId,
}: Props): JSX.Element | null => {
  const canImportDrivers = useCanImportedDriversForMcQuery(
    isNonEmptyString(mainContractorId)
      ? {
          variables: { mcId: mainContractorId },
        }
      : { skip: true },
  )

  const fileRef = React.useRef<HTMLInputElement>(null)
  const [loading, setLoading] = React.useState(false)

  const [uploadImportedDrivers] = useUploadImportedDriversMutation()

  const importStatus = React.useMemo(() => {
    if (canImportDrivers.loading) return 'loading'
    if (canImportDrivers.error) return 'error'
    return canImportDrivers.data?.canImportDrivers ? 'ok' : 'already-imported'
  }, [
    canImportDrivers.data?.canImportDrivers,
    canImportDrivers.error,
    canImportDrivers.loading,
  ])

  const onChangeFile = React.useCallback(
    async (evt: React.ChangeEvent<HTMLInputElement>) => {
      try {
        setLoading(true)
        evt.stopPropagation()
        evt.preventDefault()

        invariant(
          isNonEmptyString(mainContractorId),
          'There was an issue and we could not get the main contractor ID',
        )

        const { files } = evt.currentTarget

        if (!files || files.length === 0) {
          return triggerErrorModal({
            description:
              'There was an issue and we could not get the file you tried to export',
          })
        }

        const [file] = files
        if (file.size > MAX_FILE_SIZE) {
          return triggerErrorModal({
            description: 'File size is too big to import',
          })
        }

        const spreadsheet = await fileToBase64String(file, true)

        const { data } = await uploadImportedDrivers({
          variables: {
            input: { mainContractorId, base64File: spreadsheet },
          },
        })

        importDriverErrorInvariant(data?.uploadImportedDrivers)

        canImportDrivers.refetch()

        if (
          data?.uploadImportedDrivers.unimportedDriverEmails &&
          data?.uploadImportedDrivers.unimportedDriverEmails.length > 0
        ) {
          //handle duplicate/existing emails
          const onConfirm = () =>
            showToastNotification({
              id: 'imported-drivers-existing-emails',
              type: 'info',
              description:
                'Only some of the drivers were successfully imported',
              duration: 7000,
            })

          return pushModal({
            id: 'imported-drivers-failed-invites',
            component: createComponent(ConfirmModal, {
              variant: 'warning',
              title: (
                <span className='text-red'>Uh, oh! There was a problem.</span>
              ),
              confirmButtonContent: 'I understand.',
              description: (
                <div className='mt-6 mb-2 flex flex-col gap-y-2'>
                  <p className='font-normal'>
                    Some of the emails of the drivers you tried to upload{' '}
                    <strong>already exist</strong> in our system.{' '}
                  </p>
                  <p className='mb-5 font-normal'>
                    Because of this <strong>we could not import</strong> them.
                  </p>
                  <div>
                    <p className='mb-2 font-semibold'>
                      List of existing emails of drivers we could not import:
                    </p>
                    <ul className='flex list-disc flex-col gap-y-2'>
                      {data?.uploadImportedDrivers.unimportedDriverEmails.map(
                        (email, ix) => (
                          <li className='ml-6' key={ix}>
                            {email}
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                </div>
              ),
              onConfirm,
            }),
          })
        }

        //handle succesfully importing all drivers
        showToastNotification({
          id: 'imported-drivers-success',
          type: 'success',
          description: 'All the drivers uploaded were successfully imported',
          duration: 7000,
        })

        return
      } catch (error) {
        triggerErrorModal({
          description:
            error instanceof ImportDriverError
              ? 'This upload has failed because there is at least one error within the CSV file. Please make any changes needed and try again.'
              : undefined,
          error,
        })
      } finally {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (fileRef.current) fileRef.current.value = null
        setLoading(false)
      }
    },
    [canImportDrivers, mainContractorId, uploadImportedDrivers],
  )

  const label = React.useMemo(() => {
    switch (importStatus) {
      case 'loading':
        return 'Loading...'
      case 'error':
        return 'Import is unavailable.'
      case 'already-imported':
        return 'Already imported.'
      case 'ok':
        return 'Upload drivers.'
    }
  }, [importStatus])

  return (
    <Button
      testId={testId}
      className='rounded-full bg-purple px-6 py-3 text-sm font-bold tracking-wider text-white shadow-md hover:bg-purple-700 disabled:cursor-not-allowed disabled:bg-grey-dark disabled:text-black/20'
      disabled={loading || importStatus !== 'ok'}
      onClick={() => fileRef.current?.click()}
      loading={loading || importStatus === 'loading'}
    >
      <input
        data-testid={testId + '-hidden-input'}
        ref={fileRef}
        type='file'
        className='hidden'
        multiple={false}
        accept='text/csv'
        onChange={onChangeFile}
      />
      <div className='flex flex-row items-center gap-x-2'>
        <UploadCloudSVG />
        <p className='whitespace-nowrap'>{label}</p>
      </div>
    </Button>
  )
}

export default ImportDriversButton
