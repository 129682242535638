/**
 * I apologise to whomever stumbles across this, but the stupid react-select
 * option type is so complicated and just wont work quite right with our own
 * Option type. I tried. I failed.
 */

import cx from 'classnames'
import { kebabCase } from 'lodash'
import * as React from 'react'
import Select from 'react-select'

import { Option, isOption } from '../../../../types'

import styles from './styles'

interface Props {
  instanceId: string
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  placeholder?: string | ReactChild
  options?: Option[]
  option?: Optional<Option | string>
  onChange?: (option: Optional<Option>) => void
  selectProps?: Select['props']
  testId?: string
}

const Dropdown = ({
  testId,
  instanceId,
  className,
  placeholder,
  selectProps,
  options = [],
  option,
  onChange,
  ...props
}: Props & Omit<JSX.IntrinsicElements['div'], keyof Props>): JSX.Element => {
  const value = React.useMemo(() => {
    return typeof option === 'string'
      ? options.find((opt) => opt.value === option) ?? null
      : option ?? null
  }, [option, options])

  const handleChange = React.useCallback(
    (o) => onChange?.(isOption(o) ? o : null),
    [onChange],
  )

  return (
    <div
      {...props}
      className={cx('min-w-sm', className)}
      data-testid={testId ? kebabCase(testId) : undefined}
    >
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore

        <Select
          {...selectProps}
          instanceId={instanceId}
          placeholder={placeholder}
          styles={styles}
          isClearable
          isMulti={false}
          blurInputOnSelect={true}
          options={options}
          value={value}
          onChange={handleChange}
        />
      }
    </div>
  )
}

export default React.memo(Dropdown)
