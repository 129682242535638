import { CompressImageOptions } from '~shared/services/api/image/compress'

export const MAX_TRPC_SIZE = 20e6 // 20 Megabytes = 20MB
export const MAX_FILE_SIZE = 10e6 // 10 Megabytes = 10MB
export const MAX_IMAGE_SIZE = 10e6 // 10 Megabytes = 10MB
export const MAX_DOWNLOAD_PROXY_INCOMING_REQUEST_SIZE = 10e6 // 10 Megabytes = 10MB

export const FEATURE_RESIZER_ACTIVE = true

export const CompressConfig: Record<
  'DocumentEditor' | 'GenericIcon' | 'Signature' | 'ApplicationTaskEvidence',
  CompressImageOptions
> = {
  DocumentEditor: {
    format: 'jpeg',
    maxDimension: 2048,
    quality: 80,
  },
  GenericIcon: {
    format: 'png',
    maxDimension: 512,
    quality: 80,
  },
  Signature: {
    format: 'png',
    maxDimension: 512,
    quality: 80,
  },
  ApplicationTaskEvidence: {
    format: 'jpeg',
    maxDimension: 2048,
    quality: 80,
  },
}

export const ALLOWED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
]
