import cx from 'classnames'
import * as React from 'react'

interface Props {
  keys: {
    count?: string
    key: string
    testId?: string
  }[]
}

const colors = [
  {
    background: 'bg-theme',
    foreground: 'text-white',
  },
  {
    background: 'bg-theme/30',
    foreground: 'text-black',
  },
]

export const PieChartKey = React.memo<Props>(({ keys }) => {
  return (
    <>
      {keys.map(({ key, count, ...rest }, idx) => {
        const color = colors[idx % colors.length]
        const testId = `${rest.testId || idx}`

        return (
          <React.Fragment key={idx}>
            <p
              className='flex items-center'
              data-testid={`pie-chart-key-${testId}-text`}
            >
              <span
                className={cx(
                  'mr-2 inline-block h-3 w-3 rounded-full',
                  color.background,
                )}
              />
              {key}
            </p>
            {count ? (
              <div
                className={cx(
                  'min-w-8 ml-44 -mt-4 mb-1 h-4 rounded-full px-2',
                  color.background,
                )}
                data-testid={`pie-chart-key-${testId}-count`}
              >
                <p
                  className={cx(
                    'text-center text-xs font-bold',
                    color.foreground,
                  )}
                >
                  {count}
                </p>
              </div>
            ) : null}
          </React.Fragment>
        )
      })}
    </>
  )
})

PieChartKey.displayName = 'PieChartKey'
