import cx from 'classnames'
import { kebabCase } from 'lodash'
import * as React from 'react'
import useFitText from 'use-fit-text'

import DriverIcon from '~shared/components/Icons/svg/driver.svg'
import PaymentsIcon from '~shared/components/Icons/svg/payments.svg'
import Loading from '~shared/components/Loading/Loading'

import { formatNumbers } from '@/dashboard/utils/formatNumbers'
import { StatType } from '@/dashboard/utils/stats'

interface Props {
  title: string
  description: string
  value: StatType | string
  colour: 'grey-darker' | 'red' | 'green' | 'purple' | 'black' | 'orange'
  secondaryColour?: string
  testId?: string
  iconName?: 'driver' | 'payments'
  difference?: StatType | null
}

const StatTile = ({
  testId,
  className,
  title,
  description,
  value,
  colour,
  secondaryColour = 'white',
  iconName,
  difference,
  ...props
}: Props & JSX.IntrinsicElements['div']): JSX.Element => {
  const { fontSize, ref } = useFitText({
    maxFontSize: 370,
    minFontSize: 100,
  })

  const bgColour = React.useMemo(() => {
    switch (colour) {
      case 'orange':
        return 'bg-orange-300'
      case 'green':
        return 'bg-green'
      case 'grey-darker':
        return 'bg-grey-darker'
      case 'purple':
        return 'bg-purple'
      case 'red':
        return 'bg-red'
      case 'black':
        return 'bg-black'
    }
  }, [colour])

  return (
    <div
      data-testid={testId ? kebabCase(testId) : undefined}
      {...props}
      className={cx(
        `relative flex h-full min-h-150 w-full flex-row overflow-hidden rounded-2xl shadow-md`,
        bgColour,
        className,
        value === 'error' ? 'scale-90 opacity-50' : null,
      )}
    >
      <div className={`flex-1 p-5 text-${secondaryColour}`}>
        <div className='mr-6'>
          <h3 className='mb-2 text-xl font-bold'>{title}</h3>
          <p className='text-xs'>{description}</p>
        </div>
      </div>
      <div className='relative flex flex-1 items-center justify-center px-3'>
        <div className='absolute -left-6 h-64 w-screen rounded-full bg-white' />
        <div className='absolute right-0 top-0 bottom-0 w-24 bg-white' />
        <div
          ref={ref}
          style={{ fontSize, maxWidth: 155 }}
          className={`relative font-bold text-${colour} -ml-4`}
        >
          {difference === 'error' ? (
            <p className='rounded-lg bg-red-light p-1 text-xs text-red-dark'>
              Error loading stat pill
            </p>
          ) : difference === 'loading' ? (
            <Loading colour={colour} />
          ) : difference || difference === 0 ? (
            <div className='flex justify-end'>
              {difference === 0 ? (
                <div className='flex w-9 flex-row justify-around rounded-full bg-grey-darker p-1'>
                  <img
                    className='my-auto h-3 w-3'
                    src='/images/straightLine.svg'
                    alt='straight line'
                  />
                </div>
              ) : difference > 0 ? (
                <div className='flex w-auto flex-row justify-around rounded-full bg-green p-1'>
                  <img
                    className='my-auto h-2 px-1 pr-1'
                    src='/images/upwardArrow.svg'
                    alt='upward arrow'
                  />
                  <p className='px-1 text-xs font-normal text-font-white'>
                    +{Math.floor(difference * 100).toFixed(0)} %
                  </p>
                </div>
              ) : (
                <div className='flex w-auto flex-row justify-around rounded-full bg-red p-1'>
                  <img
                    className='my-auto h-2  px-1 pr-1'
                    src='/images/downwardArrow.svg'
                    alt='downward arrow'
                  />
                  <p className='px-1 text-xs font-normal text-font-white'>
                    {Math.floor(difference * 100).toFixed(0)} %
                  </p>
                </div>
              )}
            </div>
          ) : null}
          {typeof value === 'number' ? (
            formatNumbers(value ?? 0)
          ) : value === 'loading' ? (
            <Loading colour={colour} />
          ) : typeof value === 'string' ? (
            value
          ) : (
            '-'
          )}
        </div>
      </div>
      <div className='absolute top-2 right-2 flex items-center justify-center rounded-full bg-grey p-1.5 text-sm text-black/50'>
        {iconName === 'payments' ? <PaymentsIcon /> : <DriverIcon />}
      </div>
      {value === 'error' ? (
        <div className='absolute inset-0 flex items-center justify-center p-3'>
          <p className='rounded-lg bg-red-light p-2 text-sm text-red-dark'>
            Could not load statistic.
            <br />
            Please try again later.
          </p>
        </div>
      ) : null}
    </div>
  )
}

export default React.memo(StatTile)
