import * as React from 'react'

import { useMode } from '~shared/hooks/useMode'

import DefaultUserDashboard from '@/dashboard/components/Dashboard/DefaultUserDashboard'
import MCPOnboardingDashboard from '@/dashboard/components/Dashboard/MCPOnboardingDashboard'
import NetworkViewDashboard from '@/dashboard/components/Dashboard/NetworkViewDashboard'
import { useMaincontractor } from '@/maincontractors/hooks/useMaincontractor'

import { AppPage } from '../types'

const IndexPage: AppPage = () => {
  const mode = useMode()
  const mcStatus = useMaincontractor()?.status

  if (mode === 'NAP') return <NetworkViewDashboard />
  if (mode === 'WAP') return <DefaultUserDashboard mode='WAP' />

  switch (mcStatus) {
    case 'ACTIVE':
      return <DefaultUserDashboard mode='MCP' />
    case 'ONBOARDING':
    default:
      return <MCPOnboardingDashboard />
  }
}

export default IndexPage
