import { kebabCase } from 'lodash'

import LimitedDashboardButton from './LimitedDashboardButton'

interface Props {
  icon: string
  title: string
  description: React.ReactChild | string
  buttonName?: React.ReactChild | string
  testId?: string
  buttonDisabled?: boolean
  href?: string
  handleSubmit?: () => Promise<unknown> | void
  customButton?: React.ReactChild
}

const LimitedSetupOption = ({
  icon,
  title,
  description,
  testId,
  buttonName,
  buttonDisabled,
  href,
  handleSubmit,
  customButton,
}: Props): JSX.Element => {
  return (
    <div
      data-testid={testId ? kebabCase(testId) : undefined}
      className='mb-6 flex h-[20rem] flex-col items-center rounded-xl bg-white px-4 py-6 drop-shadow-lg sm:h-[23rem] sm:w-[24rem] sm:justify-start sm:px-6 sm:py-12'
    >
      <div className='m-2 flex flex-col items-center justify-between'>
        <img
          src={icon}
          alt={title + ' image'}
          className='mb-3 w-20 items-center sm:w-[5.6rem]'
        />
        <h2 className='text-2xl font-extrabold'>{title}</h2>
      </div>
      <div className='flex h-full flex-col items-center justify-between'>
        <div className='text-center text-sm font-medium leading-5 tracking-wide'>
          {description}
        </div>
        <div className='mt-auto flex max-w-200 items-end justify-center'>
          {customButton ? (
            customButton
          ) : (
            <LimitedDashboardButton
              buttonDisabled={buttonDisabled}
              buttonName={buttonName}
              href={href}
              handleSubmit={handleSubmit}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default LimitedSetupOption
