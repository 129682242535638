import {
  useGetMainContractorDepotsQuery,
  useGetMainContractorsWithDepotsQuery,
} from '@wise/graphql'
import { isDefined, toOptionList } from '@wise/utils'
import { uniqBy } from 'lodash'
import * as React from 'react'

import { PortalMode } from '~shared/hooks/useMode'

export const useGetMainContractorsWithDepots = (options: {
  mode: PortalMode
  mainContractorId: Optional<string>
}) => {
  const { data: wapData, error: wapError } =
    useGetMainContractorsWithDepotsQuery({
      skip: options.mode !== 'WAP',
    })

  const { data: mcpData, error: mcpError } = useGetMainContractorDepotsQuery(
    options.mode === 'MCP' && options.mainContractorId
      ? { variables: { id: options.mainContractorId } }
      : { skip: true },
  )

  const mainContractors = React.useMemo(() => {
    if (options.mode !== 'WAP') return null
    return toOptionList({
      items: wapData?.mainContractors.mainContractors,
      value: 'id',
      label: 'name',
    })
  }, [options.mode, wapData?.mainContractors])

  const validLocations = React.useMemo(() => {
    if (options.mode === 'MCP') {
      return mcpData?.mainContractor?.locations?.filter(isDefined) ?? []
    }

    if (options.mainContractorId) {
      return (
        wapData?.mainContractors.mainContractors
          ?.find((mc) => mc?.id === options.mainContractorId)
          ?.locations?.filter(isDefined) ?? []
      )
    }
    return wapData?.mainContractors.mainContractors
      ?.flatMap((mc) => mc?.locations)
      .filter(isDefined)
  }, [
    mcpData?.mainContractor?.locations,
    options.mainContractorId,
    options.mode,
    wapData?.mainContractors,
  ])

  const locations = React.useMemo(() => {
    return uniqBy(
      toOptionList({
        items: validLocations,
        value: 'id',
        label: 'name',
      }),
      'value',
    )
  }, [validLocations])

  return {
    mainContractors,
    locations,
    error: options.mode === 'WAP' ? wapError : mcpError,
  }
}
