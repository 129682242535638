import { now } from '@wise/utils'

export const DRIVER_EVIDENCE_EXPIRY_THRESHOLD = 28
export const DRIVER_EVIDENCE_EXPIRY_STATE_THRESHOLD = 14
export const DRIVER_EVIDENCE_DASHBOARD_VERSION: `v${1 | 2}` = 'v2'

export const DRIVER_EVIDENCE_EXPIRES_TODAY = now().utcOffset(0).startOf('day')
export const DRIVER_EVIDENCE_EXPIRES_FUTURE = DRIVER_EVIDENCE_EXPIRES_TODAY.add(
  DRIVER_EVIDENCE_EXPIRY_STATE_THRESHOLD,
  'day',
).endOf('day')
