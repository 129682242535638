import { isNonEmptyString } from '@wise/utils'
import produce from 'immer'
import create from 'zustand'

export type SessionState = {
  wap: {
    viewMainContractor: string | null
    viewLocation: string | null
  }
}

interface SessionStateStore {
  state: SessionState

  setWapViewMainContractor: (value: string | null) => void
  setWapViewLocation: (value: string | null) => void
}

export const useSessionState = create<SessionStateStore>((set) => ({
  state: {
    wap: {
      viewMainContractor: null,
      viewLocation: null,
    },
  },

  setWapViewMainContractor: (value) =>
    set(
      produce<SessionStateStore>((draft) => {
        if (draft.state.wap.viewMainContractor === value) return
        draft.state.wap.viewMainContractor = isNonEmptyString(value)
          ? value
          : null
        draft.state.wap.viewLocation = null
      }),
    ),
  setWapViewLocation: (value) =>
    set(
      produce<SessionStateStore>((draft) => {
        draft.state.wap.viewLocation = isNonEmptyString(value) ? value : null
      }),
    ),
}))
