import cx from 'classnames'
import { kebabCase } from 'lodash'
import * as React from 'react'
import useFitText from 'use-fit-text'

import DriverIcon from '~shared/components/Icons/svg/driver.svg'
import Loading from '~shared/components/Loading/Loading'

import { formatNumbers } from '@/dashboard/utils/formatNumbers'
import { StatType } from '@/dashboard/utils/stats'

interface Props {
  title: string
  description: string
  value: StatType
  colour: 'purple-dark'
  secondaryColour?: string
  testId?: string
}

const MigratedStatTile = ({
  testId,
  className,
  title,
  description,
  value,
  colour,
  secondaryColour = 'white',
  ...props
}: Props & JSX.IntrinsicElements['div']): JSX.Element => {
  const { fontSize, ref } = useFitText({
    maxFontSize: 370,
    minFontSize: 100,
  })

  const colourClass = React.useMemo(() => {
    switch (colour) {
      case 'purple-dark':
        return 'bg-purple-dark'
    }
  }, [colour])

  return (
    <div
      data-testid={testId ? kebabCase(testId) : undefined}
      {...props}
      className={cx(
        `relative flex h-full min-h-150 w-full flex-row overflow-hidden rounded-2xl shadow-md`,
        colourClass,
        className,
      )}
    >
      <div className={`flex-1 p-5 text-${secondaryColour}`}>
        <div className='mr-6'>
          <h3 className='mb-2 text-xl font-bold'>{title}</h3>
          <p className='text-xs'>{description}</p>
        </div>
      </div>
      <div className='relative flex flex-1 items-center justify-center px-3'>
        <div className='absolute right-0 top-0 h-12 w-12 bg-white' />
        <div className='absolute -top-8 -right-6 flex h-56 max-h-[70%] w-56 max-w-[150%] items-center justify-center rounded-full bg-white'>
          <div
            ref={ref}
            style={{ fontSize, maxWidth: 155 }}
            className={`relative font-bold text-${colour} -ml-4`}
          >
            {typeof value === 'number' ? (
              formatNumbers(value)
            ) : value === 'loading' ? (
              <Loading colour={colour} />
            ) : null}
          </div>
        </div>
      </div>
      <div className='absolute top-2 right-2 flex items-center justify-center rounded-full bg-grey p-1.5 text-sm text-black/50'>
        <DriverIcon />
      </div>
    </div>
  )
}

export default React.memo(MigratedStatTile)
