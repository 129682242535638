import cx from 'classnames'
import { kebabCase } from 'lodash'
import * as React from 'react'

import Loading from '~shared/components/Loading/Loading'
import useTheme from '~shared/hooks/useTheme'

import { StatType } from '@/dashboard/utils/stats'

interface Props {
  title: React.ReactNode
  className?: string
  description: React.ReactNode
  percentage?: StatType | null
  pieTitle?: string
  value?: Optional<string | number>
  primaryColor?: Optional<string>
  secondaryColor?: Optional<string>
  testId?: string
  direction?: 'col' | 'row'
  children?: StatType<string>
}

const CIRCLE = (() => {
  const fullRadius = 5
  const outerStroke = 1.6
  const radius = fullRadius - outerStroke / 2
  const circumference = radius * 2 * Math.PI

  return {
    viewBox: '0 0 10 10',
    cx: 5,
    cy: 5,
    r: radius,
    circumference,
    innerStroke: outerStroke - 0.3,
    outerStroke,
  }
})()

const DIRECTION_MAP: Record<Mandatory<Props['direction']>, string> = {
  col: 'flex-col',
  row: 'flex-col 2xl:flex-row',
}

const PieChartTile = ({
  testId,
  children,
  className,
  title,
  description,
  direction = 'col',
  percentage = 0,
  value,
  pieTitle,
  primaryColor,
  secondaryColor,
  ...props
}: Props): JSX.Element => {
  const theme = useTheme()

  return (
    <div
      data-testid={testId ? kebabCase(testId) : undefined}
      {...props}
      className={cx(
        'relative flex flex-col overflow-hidden rounded-2xl bg-white p-5 shadow-md',
        className,
      )}
    >
      <div
        className={cx(
          'flex h-full transform-gpu flex-col transition-transform',
          percentage === 'loading' ? 'scale-90' : 'scale-100',
        )}
      >
        {typeof title === 'string' ? (
          <h3 className='text-lg font-bold'>{title}</h3>
        ) : (
          title
        )}
        {pieTitle ? (
          <p className='pt-1 pr-1 text-xs text-darken-md'>{pieTitle}</p>
        ) : null}
        <div
          className={cx(
            'flex grow items-center justify-end',
            DIRECTION_MAP[direction],
          )}
        >
          <svg
            className='h-screen max-h-[12rem] w-screen max-w-[12rem] flex-1 p-6'
            viewBox={CIRCLE.viewBox}
            height={1}
          >
            <circle
              cx={CIRCLE.cx}
              cy={CIRCLE.cy}
              r={CIRCLE.r}
              strokeWidth={CIRCLE.innerStroke}
              stroke={
                secondaryColor ||
                (theme === 'purple' ? '#66339957' : '#00976857')
              }
              fill='none'
            />
            <circle
              cx={CIRCLE.cx}
              cy={CIRCLE.cy}
              r={CIRCLE.r}
              strokeWidth={CIRCLE.outerStroke}
              stroke={
                primaryColor || (theme === 'purple' ? '#663399' : '#009768')
              }
              fill='none'
              className='origin-center -rotate-90'
              strokeDasharray={`${CIRCLE.circumference} ${CIRCLE.circumference}`}
              strokeDashoffset={
                CIRCLE.circumference *
                (1 - (typeof percentage === 'number' ? percentage : 0))
              }
              strokeLinecap='butt'
            />
            <text
              x='50%'
              y='50%'
              fontSize={
                children ? 2.1 - Math.min(`${children}`.length, 10) / 10 : 2
              }
              fontWeight={900}
              textAnchor='middle'
              dominantBaseline='middle'
            >
              {value ??
                (typeof percentage === 'number' && !children
                  ? Math.floor(percentage * 100).toFixed(0) + '%'
                  : children
                  ? null
                  : '-')}
              {children}
            </text>
          </svg>
          <div className='px-8 text-xs font-light'>{description}</div>
        </div>
      </div>
      <div
        className={cx(
          'absolute inset-4 flex flex-row items-center justify-center rounded-md transition-colors',
          percentage === 'loading' ? 'bg-white/90' : 'bg-white/0',
        )}
      >
        {percentage === 'loading' ? <Loading colour={theme} /> : null}
      </div>
    </div>
  )
}

export default React.memo(PieChartTile)
