import { isDefined } from '@wise/utils'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import Dashboard from '~shared/layouts/Dashboard/Dashboard'
import PathTitle from '~shared/layouts/Dashboard/PathTitle'

import { AppPage } from '~types'

const NetworkViewDashboard: AppPage = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Dashboard>
      <div className='flex w-full flex-col'>
        <div className='flex flex-1 flex-col'>
          <div className='mb-6 flex flex-row items-center'>
            <PathTitle
              noMargins
              paths={[t('path_titles.dashboard')].filter(isDefined)}
            />
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default NetworkViewDashboard
