import { OptionTypeBase, Styles } from 'react-select'

const styles: Partial<Styles<OptionTypeBase, false>> = {
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    borderRadius: 999,
    boxShadow: state.isFocused
      ? '0 0 0 2px #522580'
      : '0 1px 3px rgba(0, 0, 0, 0.1)',
    transition: 'box-shadow 100ms ease-out',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '4px 12px',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: (provided) => ({
    ...provided,
    marginRight: '6px',
  }),
  option: (provided, state) => ({
    ...provided,
    borderLeftWidth: 4,
    borderLeftColor: state.isSelected ? '#663399' : 'transparent',
    backgroundColor: state.isFocused
      ? 'rgba(102, 51, 153, 0.05)'
      : 'transparent',
    fontWeight: state.isSelected ? 900 : 400,
    borderRadius: 3,
    overflow: 'hidden',
    color: state.isSelected ? 'black' : 'rgba(0, 0, 0, .9)',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 3,
    overflow: 'hidden',
    boxShadow: '0 4px 11px rgba(0, 0, 0, 0.1)',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 3,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'rgba(0, 0, 0, 0.3)',
    fontSize: '0.9rem',
    fontWeight: 600,
  }),
}

export default styles
